@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}
.subtitle-paola {
  font-size: 0.7rem;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.05em;
  background: linear-gradient(45deg, #7a98f3, #377cfb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.subtitle-paola:hover {
  transform: translateY(-1px);
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}